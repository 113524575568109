import React from 'react'
import { Button, Modal } from 'antd'
import './ModalAuthUuid.css'
import { nbsp, mdash } from '../../../Constants'

function ModalAuthUuid ({ isModalOpen, onOk }) {
  const handleOk = () => onOk?.()

  return (
    <Modal
      className='modalAuthUuid'
      visible={isModalOpen}
      footer={null}
      closable={false}
      centered
    >
      <div className='header'>Номер не найден</div>
      <div className='message'>
        Укажите номер, с которого оставляли отклик. Если к нему нет доступа или хотите указать
        другой{nbsp}{mdash} сообщите наставнику
      </div>
      <div className='footer'>
        <Button
          block
          size='large'
          type='primary'
          onClick={handleOk}
        >
          Понятно
        </Button>
      </div>
    </Modal>
  )
}

export default ModalAuthUuid
