import React, { useState, useEffect, useRef, useMemo, useReducer } from 'react'
import { Layout, Button, Form, PageHeader, Checkbox, Spin, message, Select, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { useStores } from '../../../Store/Store'
import { PERSONAL_DATA_POLICY_PAGE, ELECTRONIC_SIGNATURE_POLICY_PAGE, PROFILE_MAIN_PAGE } from '../../../routes'
import './PolicyScreenStyle.css'
import {
  useGetPersonalDataPolicyQuery,
  useGetElectronicSignaturePolicyQuery,
  useMutatePrivacyPolicyAccept,
  useMutateElectronicSignaturePolicyAccept,
  useMutatePartnerNonCitizen
} from '../../../api/react-query/partners'
import { offerScreen, policyScreen, profileScreen } from '../../../translates'
import { formLayout, policyTypes } from '../../../Constants'
import { AppConfig, partnerStatusCodes } from '../../../AppConfig'
import { useRequiredOffers } from '../../../hooks'
import { simpleReducer } from '../../../helper'

const { Content } = Layout
const { Option } = Select

const initialState = {
  citizenship: null
}

const PolicyScreenV2 = () => {
  const store = useStores()
  const [personalDataPolicy, setPersonalDataPolicy] = useState({})
  const [electronicSignaturePolicy, setElectronicSignaturePolicy] = useState({})
  const [state, setState] = useReducer(simpleReducer, initialState)

  const roleCode = useMemo(() => {
    return store?.partnerProfile?.profile?.personalData?.roleCode
  }, [store?.partnerProfile?.profile])

  const isCitizenshipShow = useMemo(() => {
    return !store?.partnerProfile?.profile?.personalData?.citizenship &&
      store?.partnerProfile?.profile?.statusCode === partnerStatusCodes.new
  }, [store?.partnerProfile?.profile])

  const isShowCheckboxes = useMemo(() => {
    return (isCitizenshipShow && state.citizenship === AppConfig.countries.Russia) || !isCitizenshipShow
  }, [isCitizenshipShow, state.citizenship])

  const isShowOffer = useMemo(() => {
    return !store?.partnerProfile?.profile?.offerActive ||
      store?.partnerProfile?.profile?.offerExpires
  }, [
    store?.partnerProfile?.profile?.offerActive,
    store?.partnerProfile?.profile?.offerExpires
  ])

  const isShowCyber = useMemo(() => {
    return !store?.partnerProfile?.profile?.cyberSecurityPolicy?.isValid
  }, [store?.partnerProfile?.profile?.cyberSecurityPolicy?.isValid])

  const isShowAssembly = useMemo(() => {
    return !store?.partnerProfile?.profile?.assemblyAndDeliveryPolicy?.isValid
  }, [store?.partnerProfile?.profile?.assemblyAndDeliveryPolicy?.isValid])

  const isShowOfferCyberAssembly = useMemo(() => {
    return isShowOffer || isShowCyber || isShowAssembly
  }, [isShowOffer, isShowCyber, isShowAssembly])

  const {
    data: dataPartnerNonCitizen,
    mutateAsync: mutatePartnerNonCitizen,
    isError: isErrorPartnerNonCitizen,
    isLoading: isLoadingPartnerNonCitizen,
    isSuccess: isSuccessPartnerNonCitizen
  } = useMutatePartnerNonCitizen()

  useEffect(() => {
    if (dataPartnerNonCitizen?.isSuccess) {
      message.success('Данные о гражданстве сохранены')
      store?.partnerProfile?.setPartnerProfile({
        profile: dataPartnerNonCitizen?.profile,
        ...(store?.partnerProfile?.id ? { id: store?.partnerProfile?.id } : {})
      })
    } else if (isErrorPartnerNonCitizen || dataPartnerNonCitizen?.errorMessage) {
      message.error(dataPartnerNonCitizen?.errorMessage || 'Ошибка сохранения данных о гражданстве')
    }
  }, [dataPartnerNonCitizen, isErrorPartnerNonCitizen, isLoadingPartnerNonCitizen, isSuccessPartnerNonCitizen])

  const onSuccessRequiredOffers = (step) => {
    if (step === AppConfig.partnerSteps.stepSuccess) {
      message.success(
        offerScreen.successTextStart + ' ' +
        (isShowOffer ? `${offerScreen.offerParts.roleOffer},` : '') +
        (isShowCyber ? `${offerScreen.offerParts.cyberSecurityPolicy},` : '') +
        (isShowAssembly ? `${offerScreen.offerParts.assemblyAndDeliveryPolicy}` : '') +
        offerScreen.successTextEnd
      )
    }
  }

  const {
    acceptListOffers,
    isLoadingAccept
  } = useRequiredOffers({
    role: roleCode,
    onSuccess: onSuccessRequiredOffers
  })

  const {
    data: dataPersonalDataPolicy,
    isError: isErrorPersonalDataPolicy,
    isSuccess: isSuccessPersonalDataPolicy,
    isLoading: isLoadingPersonalDataPolicy
  } = useGetPersonalDataPolicyQuery(undefined, {
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (!dataPersonalDataPolicy?.isSuccess) return
    if (isSuccessPersonalDataPolicy) {
      setPersonalDataPolicy(dataPersonalDataPolicy.agreement)
    }
  }, [dataPersonalDataPolicy, isSuccessPersonalDataPolicy])

  const {
    data: dataElectronicSignaturePolicy,
    isError: isErrorElectronicSignaturePolicy,
    isSuccess: isSuccessElectronicSignaturePolicy,
    isLoading: isLoadingElectronicSignaturePolicy
  } = useGetElectronicSignaturePolicyQuery({
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (!dataElectronicSignaturePolicy?.isSuccess) return
    if (isSuccessElectronicSignaturePolicy) {
      setElectronicSignaturePolicy(dataElectronicSignaturePolicy.agreement)
    }
  }, [dataElectronicSignaturePolicy, isSuccessElectronicSignaturePolicy])

  const {
    // error: errorPrivacyPolicyAccept,
    data: dataPrivacyPolicyAccept,
    mutateAsync: mutatePrivacyPolicyAccept,
    isError: isErrorPrivacyPolicyAccept,
    isLoading: isLoadingPrivacyPolicyAccept,
    isSuccess: isSuccessPrivacyPolicyAccept
  } = useMutatePrivacyPolicyAccept()

  useEffect(() => {
    if (isSuccessPrivacyPolicyAccept) {
      store?.partnerProfile?.setPartnerProfile({
        profile: dataPrivacyPolicyAccept?.profile,
        ...(store?.partnerProfile?.id ? { id: store?.partnerProfile?.id } : {})
      })
      message.success(policyScreen.personalDataPolicy.success)
    }
  }, [isSuccessPrivacyPolicyAccept, dataPrivacyPolicyAccept])

  const {
    // error: errorElectronicSignaturePolicyAccept,
    data: dataElectronicSignaturePolicyAccept,
    mutateAsync: mutateElectronicSignaturePolicyyAccept,
    isError: isErrorElectronicSignaturePolicyAccept,
    isLoading: isLoadingElectronicSignaturePolicyAccept,
    isSuccess: isSuccessElectronicSignaturePolicyAccept
  } = useMutateElectronicSignaturePolicyAccept()

  useEffect(() => {
    if (isSuccessElectronicSignaturePolicyAccept) {
      message.success(policyScreen.electronicSignaturePolicy.success)
      store?.partnerProfile?.setPartnerProfile({
        profile: dataElectronicSignaturePolicyAccept?.profile,
        ...(store?.partnerProfile?.id ? { id: store?.partnerProfile?.id } : {})
      })
    }
  }, [isSuccessElectronicSignaturePolicyAccept, dataElectronicSignaturePolicyAccept])

  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (isErrorElectronicSignaturePolicy || isErrorPersonalDataPolicy) {
      message.error(policyScreen.errors.agreement)
    }
    if (isErrorPrivacyPolicyAccept) {
      message.error(policyScreen.errors.agreementAccept)
    }
    if (isErrorElectronicSignaturePolicyAccept) {
      message.error(policyScreen.errors.agreementAccept)
    }
  }, [
    isErrorPrivacyPolicyAccept,
    isErrorPersonalDataPolicy,
    isErrorElectronicSignaturePolicyAccept,
    isErrorElectronicSignaturePolicy
  ])

  const onFinish = async values => {
    if (isCitizenshipShow && values.citizenship !== AppConfig.countries.Russia) {
      mutatePartnerNonCitizen()
    } else if (
      (store?.partnerProfile?.profile?.personalDataPolicy?.isValid || values.acceptPersonalDataPolicy) &&
      (store?.partnerProfile?.profile?.electronicSignaturePolicy?.isValid || values.acceptElectronicSignaturePolicy) &&
      (store?.partnerProfile?.profile?.cyberSecurityPolicy?.isValid || values.offerAndCyberAndAssembly) &&
      (store?.partnerProfile?.profile?.assemblyAndDeliveryPolicy?.isValid || values.offerAndCyberAndAssembly) &&
      (store?.partnerProfile?.profile?.offerActive || values.offerAndCyberAndAssembly)
    ) {
      try {
        if (values.acceptPersonalDataPolicy) {
          await mutatePrivacyPolicyAccept({
            agreementId: personalDataPolicy.id
          })
        }
        if (values.acceptElectronicSignaturePolicy) {
          await mutateElectronicSignaturePolicyyAccept({
            agreementId: electronicSignaturePolicy.id
          })
        }
        if (values.offerAndCyberAndAssembly) {
          acceptListOffers()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const myRef = useRef(null)
  useEffect(() => {
    if (myRef.current) {
      document.body.scrollIntoView({ behavior: 'smooth' })
    }
  }, [myRef])
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const [form] = Form.useForm()
  const [requiredMark, setRequiredMarkType] = useState('required')

  const onRequiredTypeChange = ({ requiredMark }) => {
    setRequiredMarkType(requiredMark)
  }

  const docParams = useMemo(() => {
    return AppConfig.partnerAdditionalRoles.includes(roleCode)
      ? `?role=${roleCode}`
      : ''
  }, [roleCode])

  const handleChange = (value) => {
    setState({ citizenship: value })
  }

  return (
    <div ref={myRef} className='height-inherit'>
      <Spin spinning={isLoadingElectronicSignaturePolicy || isLoadingPersonalDataPolicy || isLoadingAccept} size='large'>
        <Content className='PolicyScreen PolicyScreenV2'>
          <Form
            {...formLayout}
            name='basic'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            layout='vertical'
            initialValues={{ requiredMark, remember: true }}
            onValuesChange={onRequiredTypeChange}
            requiredMark={requiredMark}
          >
            <Row className='flex-space-between'>
              <Col span={24}>
                {isCitizenshipShow && (
                  <Form.Item
                    name='citizenship'
                    rules={[{ required: true }]}
                    className='citizenship'
                    label={profileScreen.input.citizenship.placeholderOnPolicy}
                  >
                    <Select onChange={handleChange}>
                      <Option value={AppConfig.countries.Russia}>Российская Федерация</Option>
                      <Option value={`not${AppConfig.countries.Russia}`}>Не Российская Федерация</Option>
                    </Select>
                  </Form.Item>
                )}

                {isShowCheckboxes && <PageHeader title={policyScreen.title} />}

                {isShowCheckboxes && isShowOfferCyberAssembly && (
                  <Form.Item
                    name='offerAndCyberAndAssembly'
                    valuePropName='checked'
                    className='align-start'
                    rules={[{
                      required: true,
                      transform: value => (value || undefined),
                      type: 'boolean',
                      message: policyScreen.agreementText
                    }]}
                  >
                    <Checkbox>
                      Соглашаюсь с условиями:{' '}
                      {
                        [
                          ...(isShowOffer ? [
                            <Link key='offer' to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offer}&role=${roleCode}`}>
                              {offerScreen.offerParts.roleOffer}
                            </Link>
                          ] : []),
                          ...(isShowCyber ? [
                            <Link key='cyber' to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.cyberSecurityPolicy}`}>
                              {offerScreen.offerParts.cyberSecurityPolicy}
                            </Link>
                          ] : []),
                          ...(isShowAssembly ? [
                            <Link key='assembly' to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.assemblyAndDeliveryPolicy}`}>
                              {offerScreen.offerParts.assemblyAndDeliveryPolicy}
                            </Link>
                          ] : [])
                        ].map((link, index, list) => {
                          return <>{link}{index === list.length - 1 ? '' : ', '}</>
                        })
                      }
                    </Checkbox>
                  </Form.Item>
                )}

                {isShowCheckboxes && !store?.partnerProfile?.profile?.electronicSignaturePolicy?.isValid &&
                  <Form.Item
                    name='acceptElectronicSignaturePolicy'
                    valuePropName='checked'
                    className='align-start'
                    rules={[{
                      required: true,
                      transform: value => (value || undefined),
                      type: 'boolean',
                      message: policyScreen.agreementText
                    }]}
                  >
                    <Checkbox>
                      {policyScreen.electronicSignaturePolicy.placeholderPreV2}{' '}
                      <Link to={ELECTRONIC_SIGNATURE_POLICY_PAGE}>{policyScreen.electronicSignaturePolicy.placeholderV2}</Link>
                    </Checkbox>
                  </Form.Item>}

                {isShowCheckboxes && !store?.partnerProfile?.profile?.personalDataPolicy?.isValid &&
                  <Form.Item
                    name='acceptPersonalDataPolicy'
                    valuePropName='checked'
                    className='align-start'
                    rules={[{
                      required: true,
                      transform: value => (value || undefined),
                      type: 'boolean',
                      message: policyScreen.agreementText
                    }]}
                  >
                    <Checkbox>
                      {policyScreen.personalDataPolicy.placeholderPreV2}{' '}
                      <Link to={`${PERSONAL_DATA_POLICY_PAGE}${docParams}`}>{policyScreen.personalDataPolicy.placeholderV2}</Link>
                    </Checkbox>
                  </Form.Item>}
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    block
                    // size='large'
                    type='primary'
                    htmlType='submit'
                    className='continue-button margin-bottom-20'
                    loading={isLoadingPrivacyPolicyAccept || isLoadingElectronicSignaturePolicyAccept || isLoadingAccept || isLoadingPartnerNonCitizen}
                    disabled={isCitizenshipShow && !state.citizenship}
                  >
                    {policyScreen.button.continue}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Spin>
    </div>
  )
}
export default observer(PolicyScreenV2)
