import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Form, Layout, message, Spin } from 'antd'
import { observer } from 'mobx-react'

import {
  apiErrorCodes,
  partnerStatusCodes,
  AppConfig,
  DISABLE_VACCINATED,
  innDetailsStatusCodes, selfEmployed
} from '../../../AppConfig'
import { useStores } from '../../../Store/Store'
import { useDataApi } from '../../../api/useDataApi'
import {
  createApiDataFromForm,
  validateAllPhotosUploading,
  isDisabledMedicalAcceptOffer,
  getMedicalAcceptOfferErrors,
  formatDateForFrontend, getFileId, scrollToFirstError
} from '../../../helper'
import './ProfileStyle.css'
import { errors, offerScreen, profileScreen } from '../../../translates'
import ErrorBlock from './ErrorBlock'
import PersonalData from './PersonalData'
import BankDetails from './BankDetails'
import Documents from './Documents'
import { getHeaders } from '../../../api/react-query/api'
import { formLayout, policyTypes, partnerRoles4MultiOffer } from '../../../Constants'
import { Link } from 'react-router-dom'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import ExistsNfc from './ExistsNfc'
import { PlaceOfBirthFormItem, RegistrationAddressBlock } from '../../Common'
import { useRequiredOffers } from '../../../hooks'
import MedicalBookSelectFormItem from '../../Common/FormItems/Medical/MedicalBookSelectFormItem'
import MedicalAcceptOffer from '../../Common/FormItems/Medical/MedicalAcceptOffer'
import MedicalBookNumberFormItem from '../../Common/FormItems/Medical/MedicalBookNumberFormItem'
import MedicalExamDateFormItem from '../../Common/FormItems/Medical/MedicalExamDateFormItem'
import MedicalAttestationDateFormItem from '../../Common/FormItems/Medical/MedicalAttestationDateFormItem'
import { formatForFrontVehicleNumber, patternVehicleNumber } from '../../Common/FormItems/VehicleNumberFormItem'
import AuthFillButtons from './AuthFillButtons'
import InnPhotoWithDescription from './InnPhotoWithDescription'

const { Content } = Layout

const Profile = ({ onSuccess }) => {
  const [form] = Form.useForm()
  const {
    partnerProfile
  } = useStores()
  const [role, setRole] = useState(false)
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [trigger, setTrigger] = useState(false)
  const [existsNfc, setExistsNfc] = useState(partnerProfile?.profile?.personalData?.hasNfc)
  const [disabledMedicalAcceptOffer, setDisabledMedicalAcceptOffer] = useState(false)
  const [errorsMedicalAcceptOffer, setErrorsMedicalAcceptOffer] = useState([])
  const [existsExtraReg, setExistsExtraReg] = useState(false)
  const [checkResponseDone, setCheckResponseDone] = useState(false)

  useEffect(() => {
    if (partnerProfile.form?.personalData?.mobilePhone) {
      form.setFieldsValue(partnerProfile.form)

      const currentInnPhoto = getFileId(partnerProfile.form, profileScreen.input.innPhoto.name)
      if (currentInnPhoto &&
      // сбрасываем только первую фотку которую не одобрили
      // если загружена новая то не сбрасываем
        currentInnPhoto === partnerProfile?.profile?.innPhoto?.fileId &&
        partnerProfile.form?.innDetails?.status === innDetailsStatusCodes.declined &&
        partnerProfile.form?.personalData?.legalForm === selfEmployed
      ) {
        form.resetFields([profileScreen.input.innPhoto.name])
      }

      const carLicencePlate = formatForFrontVehicleNumber(partnerProfile.form?.personalData?.[profileScreen.input.carLicencePlate.name])
      form.setFieldsValue({
        personalData: {
          [profileScreen.input.isForeignVehicleNumber.name]: carLicencePlate && !patternVehicleNumber.test(carLicencePlate),
          [profileScreen.input.carLicencePlate.name]: carLicencePlate,
          citizenship: AppConfig.countries.Russia
        }
      })

      if (partnerProfile.form?.passportPhoto2?.length) {
        setExistsExtraReg(true)
      }
      setTrigger(value => !value)
    }
  }, [form, partnerProfile.form])

  // const myRef = useRef(null)
  // useEffect(() => {
  //   if (myRef.current) {
  //     document.body.scrollIntoView({ behavior: 'smooth' })
  //   }
  // }, [myRef])

  const { acceptListOffers, acceptOnlyMedicalOffer, isLoadingAccept } = useRequiredOffers({ role, onSuccess })

  /**
   * Profile update
   */
  const [{ data: profileUpdated, isError: errorProfileUpdated, isLoading }, doFetchProfileUpdate] = useDataApi({})

  useEffect(() => {
    if (profileUpdated?.isSuccess && profileUpdated.profile?.statusCode === partnerStatusCodes.draft) {
      message.success(profileScreen.messages.draftSaved)
      partnerProfile.setPartnerProfile({
        ...profileUpdated,
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
    }
    if (profileUpdated?.isSuccess && profileUpdated.profile?.statusCode === partnerStatusCodes.profileFilled) {
      message.success(profileScreen.messages.profileSaved)
      partnerProfile.setPartnerProfile({
        ...profileUpdated,
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
      acceptListOffers()
    }
    if (profileUpdated?.isSuccess &&
        (profileUpdated.profile?.statusCode === partnerStatusCodes.verification || profileUpdated.profile?.statusCode === partnerStatusCodes.selfFeeCheck)
    ) {
      message.success(profileScreen.messages.profileSaved)
      if (partnerProfile?.visible?.personalData && partnerProfile?.profile?.lmkDetails?.hasMedicalBook) {
        partnerProfile.setPartnerProfile({
          ...profileUpdated,
          ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
        })
        acceptOnlyMedicalOffer(profileUpdated?.profile)
      } else {
        partnerProfile.setPartnerProfile({
          ...profileUpdated,
          ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
        })
        onSuccess(AppConfig.partnerSteps.stepSuccess)
      }
    }

    if (profileUpdated?.errorCode === apiErrorCodes.innDuplicate) {
      setBackendFieldsErrors({ inn: [profileUpdated?.errorMessage] })
    }
  }, [
    profileUpdated,
    errorProfileUpdated,
    onSuccess,
    partnerProfile
  ])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(Object.keys(backendFieldsErrors).map(key => ({
        name: key,
        errors: backendFieldsErrors[key]
      })))
    }
  }, [backendFieldsErrors])

  const [
    {
      data: profileChecked,
      isError: isErrorProfileChecked,
      isLoading: isLoadingProfileChecked
    },
    doFetchProfileCheck
  ] = useDataApi({})

  useEffect(() => {
    if (profileChecked?.isSuccess) {
      partnerProfile.setPartnerProfile({
        ...profileChecked,
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
      setCheckResponseDone(true)
    }
  }, [profileChecked, isErrorProfileChecked, partnerProfile, setCheckResponseDone])

  const onFinish = (values) => {
    if (!values.accept || (!values.medicalAcceptOffer && partnerProfile?.visible?.personalData && values.lmkDetails?.hasMedicalBook)) return

    setBackendFieldsErrors({})
    if (!validateAllPhotosUploading(values)) {
      message.error(errors.documentsUploading)
      return
    }

    delete values?.personalData?.hasNfc
    if (values?.personalData && partnerProfile?.profile?.showNfc && partnerProfile?.visible?.personalData) {
      values.personalData.hasNfc = existsNfc
    }

    if (!partnerProfile?.profile?.showNfc && checkResponseDone) {
      values.personalData.hasNfc = false
    }

    const info = createApiDataFromForm(values)
    // Если коррекция - отправляем только поля, которые необходимо обновить (со statusReason)
    if (partnerProfile?.isCorrectionStatus) {
      Object.keys(partnerProfile?.profile).forEach(key => {
        if (!partnerProfile?.profile?.[key]?.allowEdit) {
          delete info[key]
        }
      })
    }

    const passportPhotoExtraName = profileScreen.input.passportPhotoExtraReg.name
    if (partnerProfile?.profile?.[passportPhotoExtraName]?.fileId &&
      !info?.[passportPhotoExtraName] &&
      partnerProfile?.profile?.statusCode !== partnerStatusCodes.correction
    ) {
      info[passportPhotoExtraName] = 'none'
    }

    if (partnerProfile?.profile?.statusCode === partnerStatusCodes.correction &&
      partnerProfile?.profile?.personalData?.legalForm === selfEmployed
    ) {
      info.innDetails = { inn: partnerProfile?.profile?.innDetails?.inn }
    }

    doFetchProfileUpdate('partner/profile/', {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify({
        info
      })
    })
  }

  const onFinishFailed = errorInfo => {
    scrollToFirstError(errorInfo?.errorFields)
    console.log('Failed:', errorInfo, errorInfo.values.personalData)
  }

  const onFieldsChange = fields => {
    const values = form.getFieldsValue()
    setDisabledMedicalAcceptOffer(isDisabledMedicalAcceptOffer(values))
    setErrorsMedicalAcceptOffer(getMedicalAcceptOfferErrors(values))
    if (partnerProfile?.profile?.statusCode !== partnerStatusCodes.free) {
      return false
    }
    if (['bankAccount', 'bankBik', 'bankName'].includes(fields[0]?.name?.[0])) {
      partnerProfile.setPartnerProfile({
        profile: {
          ...partnerProfile.profile,
          bankDetails: {
            ...partnerProfile.profile.bankDetails,
            [fields[0].name[0]]: fields[0].value,
            bankDetailsPhoto: null
          }
        },
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
    }
  }

  const onContinueLater = () => {
    const values = form.getFieldsValue()

    delete values?.personalData?.hasNfc
    if (values?.personalData && partnerProfile?.profile?.showNfc && partnerProfile?.visible?.personalData) {
      values.personalData.hasNfc = existsNfc
    }

    const info = createApiDataFromForm(values)

    doFetchProfileUpdate('partner/profile/draft', {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify({
        info
      })
    })
  }

  const handleUpdateForm = () => {
    partnerProfile.updateForm({
      ...partnerProfile?.form,
      ...form.getFieldsValue()
    })
  }

  const handleUpdateImage = (item, info) => {
    partnerProfile.updateForm({ ...partnerProfile?.form, ...form.getFieldsValue(), [item.name]: info.fileList })
    setTrigger(Math.random())
  }
  const handleDeleteImage = (item) => {
    partnerProfile.updateForm({ ...partnerProfile?.form, ...form.getFieldsValue(), [item.name]: [] })
    setTrigger(Math.random())
  }
  const handleUploadingImage = (item) => {
    if (item?.name === profileScreen.input.passportPhoto2.name) {
      setExistsExtraReg(true)
    }
    setTrigger(Math.random())
  }

  const documentList = useMemo(() => AppConfig.documentNameList
    .filter(d => ![
      profileScreen.input.lmkMainPhoto.name,
      profileScreen.input.lmkAllowancePhoto.name,
      profileScreen.input.lmkAttestationPhoto.name,
      ...(DISABLE_VACCINATED ? [profileScreen.input.vaccinacionPhoto.name] : [])
    ].includes(d))
    .filter(
      d => {
        return d !== profileScreen.input.passportPhoto3.name ||
        (d === profileScreen.input.passportPhoto3.name && partnerProfile?.profile?.showNfc && existsNfc && partnerProfile?.required?.passportPhoto3)
      }
    ).filter(
      d => {
        return d !== profileScreen.input.passportPhotoExtraReg.name ||
          (d === profileScreen.input.passportPhotoExtraReg.name && existsExtraReg && partnerProfile?.profile?.showNfc && existsNfc)
      }
    ).filter(d => d !== profileScreen.input.innPhoto.name), [
    AppConfig.documentNameList,
    existsNfc,
    partnerProfile?.required,
    partnerProfile?.profile?.showNfc,
    existsExtraReg
  ])

  const filterDocuments = useCallback((d) => partnerProfile?.visible?.[d], [partnerProfile?.visible])

  const medicalDocumentList = useMemo(() => AppConfig.documentNameList
    .filter(d => [
      profileScreen.input.lmkMainPhoto.name,
      profileScreen.input.lmkAllowancePhoto.name,
      profileScreen.input.lmkAttestationPhoto.name
    ].includes(d))
    .filter(
      d => {
        return d !== profileScreen.input.lmkMainPhoto.name ||
          (d === profileScreen.input.lmkMainPhoto.name &&
            partnerProfile?.profile?.lmkDetails?.hasMedicalBook &&
            partnerProfile?.required?.lmkMainPhoto &&
            partnerProfile?.visible?.personalData)
      }
    )
    .filter(
      d => {
        return d !== profileScreen.input.lmkAllowancePhoto.name ||
          (d === profileScreen.input.lmkAllowancePhoto.name &&
            partnerProfile?.profile?.lmkDetails?.hasMedicalBook &&
            partnerProfile?.required?.lmkAllowancePhoto &&
            partnerProfile?.visible?.personalData)
      }
    )
    .filter(
      d => {
        return d !== profileScreen.input.lmkAttestationPhoto.name ||
          (d === profileScreen.input.lmkAttestationPhoto.name &&
            partnerProfile?.profile?.lmkDetails?.hasMedicalBook &&
            partnerProfile?.required?.lmkAttestationPhoto &&
            partnerProfile?.visible?.personalData)
      }
    ), [
    AppConfig.documentNameList,
    partnerProfile?.required,
    partnerProfile?.profile?.lmkDetails?.hasMedicalBook
  ])

  useEffect(() => {
    setExistsNfc(Boolean(partnerProfile?.profile?.personalData?.hasNfc))
    const country = form.getFieldValue(['personalData', 'registrationAddress', 'country'])
    form.setFieldsValue({
      personalData: {
        registrationAddress: { country: country ?? profileScreen.input.registrationAddresscountry.placeholder }
      }
    })
  }, [partnerProfile?.profile?.personalData?.hasNfc])

  useEffect(() => {
    if (Object.keys(AppConfig.registrationTypes).includes(partnerProfile?.profile?.personalData?.registrationKindCode)) {
      form.setFieldsValue({ personalData: { registrationKind: partnerProfile?.profile?.personalData?.registrationKindCode } })
    }
  }, [partnerProfile?.profile?.personalData?.registrationKindCode])

  const handleChangeFormAndCheck = (hasNfc) => {
    const values = form.getFieldsValue()
    values.personalData = {
      ...values.personalData,
      hasNfc
    }

    const profileLmkDetails = partnerProfile?.profile?.lmkDetails
    values.lmkDetails = {
      ...(profileLmkDetails?.medicalBookNumber && { medicalBookNumber: profileLmkDetails?.medicalBookNumber }),
      ...(profileLmkDetails?.examinationDate && { examinationDate: formatDateForFrontend(profileLmkDetails?.examinationDate) }),
      ...(profileLmkDetails?.attestationDate && { attestationDate: formatDateForFrontend(profileLmkDetails?.attestationDate) }),
      ...values.lmkDetails
    }
    const info = createApiDataFromForm(values)

    doFetchProfileCheck('partner/profile/check', {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        info
      })
    })
  }

  const handleChangeNfc = (checked) => {
    setExistsNfc(checked)
    handleChangeFormAndCheck(checked)
    setTrigger(Math.random())
  }

  const isShowAuthFillButtons = useMemo(() => {
    return !partnerProfile?.profile?.tinkoffData?.surname &&
      !partnerProfile?.profile?.sberIdData?.surname &&
      !isLoadingAccept &&
      partnerProfile?.profile?.statusCode &&
      [
        partnerStatusCodes.new, partnerStatusCodes.free, partnerStatusCodes.draft
      ].includes(partnerProfile?.profile?.statusCode)
  }, [
    partnerProfile?.profile?.tinkoffData?.surname,
    partnerProfile?.profile?.sberIdData?.surname,
    isLoadingAccept,
    partnerProfile?.profile?.statusCode
  ])

  return (
    <Content className='Profile'>
      <ErrorBlock />
      {isShowAuthFillButtons && <AuthFillButtons />}
      <Form
        {...formLayout}
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        form={form}
        layout='vertical'
      >
        <PersonalData
          form={form}
          doFetchProfileCheck={doFetchProfileCheck}
          isLoading={isLoadingProfileChecked}
          setRole={setRole}
          trigger={trigger}
          hasNfc={partnerProfile?.profile?.showNfc && partnerProfile?.visible?.personalData ? existsNfc : null}
          // disabledMedicalAcceptOffer={disabledMedicalAcceptOffer}
          // onMedicalOfferShow={handleUpdateForm}
        />

        {
          !isLoadingAccept && partnerProfile?.profile?.innDetails?.status !== innDetailsStatusCodes.accepted && (
            <InnPhotoWithDescription
              profile={partnerProfile?.profile}
              form={form}
            />
          )
        }

        <BankDetails form={form} />

        {
          partnerProfile?.visible?.personalData && (
            <>
              <MedicalBookSelectFormItem
                required={partnerProfile?.required?.personalData}
                onSelect={() => {
                  handleUpdateForm()
                  handleChangeFormAndCheck(existsNfc)
                }}
              />

              {partnerProfile?.form?.lmkDetails?.hasMedicalBook && (
                <>
                  <MedicalBookNumberFormItem required={partnerProfile?.form?.lmkDetails?.hasMedicalBook} />
                  <MedicalExamDateFormItem required={partnerProfile?.form?.lmkDetails?.hasMedicalBook} />
                  <MedicalAttestationDateFormItem required={partnerProfile?.form?.lmkDetails?.hasMedicalBook} />
                  <Documents
                    title={profileScreen.titleMedical}
                    documents={medicalDocumentList}
                    trigger={trigger}
                    form={form}
                    required={partnerProfile?.required}
                    filterDocuments={filterDocuments}
                    onUpdate={handleUpdateImage}
                    onUploading={handleUploadingImage}
                    onDelete={handleDeleteImage}
                    onPhotoDoc={handleUpdateForm}
                  />
                </>
              )}
            </>
          )
        }

        {
          partnerProfile?.profile?.showNfc && partnerProfile?.visible?.personalData &&
            <ExistsNfc form={form} checked={existsNfc} onChange={handleChangeNfc} />
        }
        {
          partnerProfile?.visible?.personalData && (
            (partnerProfile?.profile?.showNfc && existsNfc) || partnerProfile?.profile?.lmkDetails?.hasMedicalBook
          ) && (
            <>
              <RegistrationAddressBlock
                onChangeFormAndCheck={() => handleChangeFormAndCheck(existsNfc)}
                registrationFlow={partnerProfile?.profile?.registrationFlow && existsNfc}
                required={{
                  zipCode: true,
                  country: true,
                  region: true,
                  street: true,
                  house: true
                }}
                disabled={{ country: true }}
              />
              {partnerProfile?.form?.lmkDetails?.hasMedicalBook && (
                <MedicalAcceptOffer
                  disabled={disabledMedicalAcceptOffer}
                  errors={errorsMedicalAcceptOffer}
                  onMedicalOfferShow={handleUpdateForm}
                />
              )}
            </>
          )
        }
        {
          partnerProfile?.profile?.showNfc && partnerProfile?.visible?.personalData && existsNfc && (
            <PlaceOfBirthFormItem requiredRule />
          )
        }

        <Documents
          documents={documentList}
          trigger={trigger}
          form={form}
          required={partnerProfile?.required}
          filterDocuments={filterDocuments}
          onUpdate={handleUpdateImage}
          onUploading={handleUploadingImage}
          onDelete={handleDeleteImage}
          onPhotoDoc={handleUpdateForm}
          disabledUploadModal={!(partnerProfile?.profile?.showNfc && existsNfc) || !partnerProfile?.visible?.[profileScreen.input.passportPhotoExtraReg.name]}
        />

        <div className=''>
          <Spin spinning={isLoading || isLoadingAccept} size='large'>
            {partnerProfile?.profile?.statusCode !== partnerStatusCodes.correction &&
          partnerProfile?.profile?.statusCode !== partnerStatusCodes.free &&
            <Form.Item>
              <Button
                ghost
                block
                size='large'
                type='primary'
                onClick={onContinueLater}
                className='margin-top-30'
              >
                {profileScreen.button.continueLater}
              </Button>
            </Form.Item>}
            <Form.Item
              name='accept'
              valuePropName='checked'
              rules={[{
                required: true,
                transform: value => (value || undefined),
                type: 'boolean',
                message: offerScreen.offerText
              }]}
              className='offer'
            >
              <Checkbox disabled={!role}>
                {offerScreen.input.accept.placeholder}:{' '}
                <Link
                  disabled={!role}
                  to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offer}&role=${role}`}
                  onClick={handleUpdateForm}
                >
                  {offerScreen.offerParts.roleOffer}
                </Link>
                {
                  partnerRoles4MultiOffer.includes(role) && (
                    <>
                      ,{' '}
                      <Link
                        disabled={!role}
                        to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.cyberSecurityPolicy}`}
                        onClick={handleUpdateForm}
                      >
                        {offerScreen.offerParts.cyberSecurityPolicy}
                      </Link>,{' '}
                      <Link
                        disabled={!role}
                        to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.assemblyAndDeliveryPolicy}`}
                        onClick={handleUpdateForm}
                      >
                        {offerScreen.offerParts.assemblyAndDeliveryPolicy}
                      </Link>.
                    </>
                  )
                }
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                block
                size='large'
                type='primary'
                htmlType='submit'
                className='continue-button'
              >
                {profileScreen.button.send}
              </Button>
            </Form.Item>
          </Spin>
        </div>
      </Form>
    </Content>
  )
}
export default observer(Profile)
